body {
  margin: 40px;
  margin-bottom: 200px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  white-space: pre-line;
  display: flex;
  justify-content: center;
  line-height: 2em;
}

h1 {
  font-weight: 400;
}

img {
  width: 100%;
}

.outermost {
  max-width: 600px;
}

.paradiv {
  text-align: left;
}

.paradiv br {
  height: 20px;
  display: block;
  content: " ";
}

.nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.nav li {
  margin: 20px;
}

.hero {}

.block-section {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}

.block-anchor {
  margin: 20px;
  color: #333;
  text-decoration: none;
}

.block-div {
  background-color: rgb(250, 245, 240);
  border-radius: 10px;
  /* border: 3px solid #333; */
  width: 225px;
  text-align: center;
  transition: 0.5s ease;
  filter: drop-shadow(0 4px 1px rgb(200, 200, 220));
}

.block-div:hover {
  width: 250px;
  /* transition: 0.5s ease; */
}

.block-image {
  border-radius: 7px;
  width: 100%;
}

.inner-block {
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.faded {
  color: #999;
  font-weight: 600;
}

.summary {
  font-weight: 600;
  color: #999;
  font-size: 1.2em;
  font-style: italic;
  text-align: left;
  padding-left: 20px;
  border-left: 4px #999 solid;
}

.favorite-songs {
  font-style: italic;
  font-size: 1.2em;
  color: #999;
  text-align: left;
}

.listen-link {
  text-align: left;
  font-size: 1.2em;
}